@import '../../../../styles/variaveis.scss';

.navbarSection {
    width: 20%;
    transition: width 0.3s ease;
}

.logoSection {
    margin-top: 12%;
    margin-bottom: -14%;
}

.titlesSection {
    margin-left: 20% !important;
}

.titleSection {
    margin-top: 5% !important;
}

.contentSection {
    flex: 1;
    font-family: 'PlusJakartaSans-Medium';
    margin-left: 23% !important;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;

    @media (max-width: 480px) {
        margin-left: 10% !important;
    }

    @media (max-width: 764px) {
        margin-left: 20% !important;
    }
}

.resultCard {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headerCard {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
}

.neutralHeader {
    background-color: #f8f9fa;
    color: #333;
}

.clientInfo {
    p {
        margin-bottom: 5px;
        font-size: 0.9rem;

        strong {
            font-weight: bold;
            color: #555;
        }
    }
}

.margemInfo {
    p {
        margin-bottom: 5px;
        font-size: 0.9rem;

        strong {
            font-weight: bold;
            color: #555;
        }
    }
}

.inssLogo {
    margin-top: 3% !important;
    max-width: 100%;
}

.bankCard {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding-top: 15%;
    overflow: hidden;
    height: 150px;
    // Fixed height for consistent card sizes
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.logoContainerSmall {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.bankLogoSmall {
    max-width: 50%;
    max-height: 80%;
    object-fit: contain;
}

.selected {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.expandedTable {
    width: 100%;
}

.badgeStyle {
   background-color: $orangePrimary !important;
    padding: 0.5em 0.7em;
    border-radius: 0.4em;
}
.badgeStyleSecondary {
  background-color: $yellowSecondary !important;
  color: $orangeDanger;
  padding: 0.5em 0.7em;
  border-radius: 0.4em;
}
.alertSuccess {
  display: flex;
  align-items: center;
  background-color: #d4edda;
  color: #155724;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
}

.alertDanger {
  display: flex;
  align-items: center;
  background-color: #f8d7da;
  color: #721c24;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
}

.iconSuccess {
  color: green;
  font-size: 1.2rem;
}

.iconError {
  color: red;
  font-size: 1.2rem;
}